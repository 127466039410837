import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_datetime = _resolveComponent("ion-datetime")!
  const _component_the_header = _resolveComponent("the-header")!
  const _component_calendar_day_item = _resolveComponent("calendar-day-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_infinite_scroll_content = _resolveComponent("ion-infinite-scroll-content")!
  const _component_ion_infinite_scroll = _resolveComponent("ion-infinite-scroll")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_the_header, { "tab-title": "Calendar" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_datetime, {
            style: {"color":"black"},
            max: "2023-12-31",
            min: "2020-12-31",
            size: "cover",
            presentation: "date",
            "display-format": "MMMM YYYY",
            value: "2022-02-07T13:47:20.789"
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, { fullscreen: "true" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_list, null, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.days, (d) => {
                return (_openBlock(), _createBlock(_component_calendar_day_item, {
                  key: d.date,
                  day: d
                }, null, 8, ["day"]))
              }), 128))
            ]),
            _: 1
          }),
          _createVNode(_component_ion_infinite_scroll, {
            threshold: "100px",
            id: "infinite-scroll"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_infinite_scroll_content, {
                "loading-spinner": "bubbles",
                "loading-text": "Loading more data..."
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}