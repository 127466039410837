import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item_divider = _resolveComponent("ion-item-divider")!
  const _component_calendar_day = _resolveComponent("calendar-day")!
  const _component_ion_item_group = _resolveComponent("ion-item-group")!

  return (_openBlock(), _createBlock(_component_ion_item_group, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_item_divider, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_label, { class: "text-center" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($props.day.date), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.day.experiences, (exp) => {
        return (_openBlock(), _createBlock(_component_calendar_day, {
          key: exp.id,
          experience: exp
        }, null, 8, ["experience"]))
      }), 128))
    ]),
    _: 1
  }))
}