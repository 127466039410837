import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_activity_card = _resolveComponent("activity-card")!
  const _component_event_card = _resolveComponent("event-card")!

  return ($props.experience.activity)
    ? (_openBlock(), _createBlock(_component_activity_card, {
        key: 0,
        activity: $props.experience
      }, null, 8, ["activity"]))
    : (_openBlock(), _createBlock(_component_event_card, {
        key: 1,
        event: $props.experience
      }, null, 8, ["event"]))
}