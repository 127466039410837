

import CalendarDay from "@/components/card/CalendarDayCards.vue";
export default {
  name:'CalendarDayItem',
  components: {
    CalendarDay
  },
  props:{
    day: Object
  },

}
