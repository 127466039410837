
import ActivityCard from "@/components/card/ActivityCard.vue";
import EventCard from "@/components/card/EventCard.vue";

export default {

  name: 'CalendarDay',
  components:{
    ActivityCard,
    EventCard,
  },
  props:{
    experience: Object
  }


}


