
import theHeader from "@/components/theHeader.vue";
import {IonDatetime, IonList ,IonPage, IonContent} from "@ionic/vue";
import { defineComponent } from 'vue';
import CalendarDayItem from "@/components/item/CalendarDayItem.vue";

export default  defineComponent({
  name: 'Calendar',
  components: {
    CalendarDayItem,
    IonContent,
    IonList,
    IonDatetime,
    theHeader,
    IonPage
  },

  data(){
    return{
      days:[
        {
          date:'02/08/2022',
          experiences:[
            {
              id:1,
              activity:false,
              name:'Miami Marathon & Half',
              company: 'Life Time',
              going: 3,
              date: '02/08/22',
              location: 'Miami, FL'
            },
            {
              id:2,
              activity:true,
              name:'1/2 Way to the Weekend - brick',
              group: 'Race Junkies',
              startTime: '6:00 am',
              going: 20,
              date: '02/08/22'
            },
          ]
        },
        {
          date:'02/09/2022',
          experiences:[
            {
              id:1,
              activity:false,
              name:'Miami Marathon & Half',
              company: 'Life Time',
              going: 3,
              date: '02/08/22',
              location: 'Miami, FL'
            },
            {
              id:2,
              activity:true,
              name:'1/2 Way to the Weekend - brick',
              group: 'Race Junkies',
              startTime: '6:00 am',
              going: 20,
              date: '02/08/22'
            },
          ]
        },
        {
          date:'02/10/2022',
          experiences:[
            {
              id:1,
              activity:false,
              name:'Miami Marathon & Half',
              company: 'Life Time',
              going: 3,
              date: '02/08/22',
              location: 'Miami, FL'
            },
            {
              id:2,
              activity:true,
              name:'1/2 Way to the Weekend - brick',
              group: 'Race Junkies',
              startTime: '6:00 am',
              going: 20,
              date: '02/08/22'
            },
          ]
        },
        {
          date:'02/11/2022',
          experiences:[
            {
              id:1,
              activity:false,
              name:'Miami Marathon & Half',
              company: 'Life Time',
              going: 3,
              date: '02/08/22',
              location: 'Miami, FL'
            },
            {
              id:2,
              activity:true,
              name:'1/2 Way to the Weekend - brick',
              group: 'Race Junkies',
              startTime: '6:00 am',
              going: 20,
              date: '02/08/22'
            },
          ]
        },
      ]
    }
  }
});
